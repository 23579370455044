<template>
  <div>
    <section
      class="locations-page"
      v-if="this.$route.params.state !== undefined && this.$route.params.city == undefined"
    >
      <p class="bread-crumb">
        <span class="bread-crumb-contry underline" @click="$router.go(-1)">{{ country }}</span> /
        <span class="location-bread-crumb text-bold">{{ selectedState }}</span>
      </p>
      <div class="state-locations-wrapper" v-if="getStateLocationsLength > 0">
        <h1>
          {{ getStateLocationsLength }} <span v-if="getStateLocationsLength > 1">EV Charging Stations in </span
          ><span v-else>EV Charging Station in </span>
          <span class="location-bread-crumb">{{ selectedState }}</span>
        </h1>
        <div class="locations-page-wrapper">
          <ul v-for="(list, index) in getStateLocations" :key="index">
            <li @click="redirectToCity(list[0].city)">
              <a
                ><strong>{{ list[0].city }}</strong> ({{ list.length }})</a
              >
            </li>
          </ul>
        </div>
      </div>
    </section>
    <router-view />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'state-page',
  components: {},
  metaInfo() {
    return {
      title: this.pageTitle,
      link: [
        {
          rel: 'canonical',
          href: this.canonicalUrl,
        },
      ],
    };
  },
  data() {
    return {
      selectedState: '',
      pageTitle: '',
      canonicalUrl: '',
      country: 'US',
    };
  },
  async created() {
    this.selectedState = this.$route.params?.state?.replaceAll('-', ' ') ?? '';
    this.pageTitle = `EV Charging Stations in ${
      this.selectedState.charAt(0).toUpperCase() + this.selectedState.slice(1)
    } | Electrify America`;
    this.canonicalUrl = `https://www.electrifyamerica.com/locations/${this.$route.params?.state}`;
    if (Object.values(this.selectedStateCityLocations).flat(Infinity).length === 0) {
      await this.$store.dispatch('locations/getAllLocations', this.$router);
    }
    this.$store.commit('locations/setSelectedStateCityLocations', this.$route?.params?.state);
  },
  computed: {
    ...mapState('locations', ['selectedStateCityLocations']),
    getStateLocations() {
      return Object.values(this.selectedStateCityLocations);
    },
    getStateLocationsLength() {
      return Object.values(this.selectedStateCityLocations).flat(Infinity).length;
    },
  },
  methods: {
    redirectToCity(city) {
      const trimCity = city.replaceAll(' ', '-').toLowerCase();
      this.$router.push({
        name: 'locations-state-cities-en',
        params: { state: this.$route.params.state, city: trimCity },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.locations-page {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  min-height: calc(100vh - 550px);
  &-wrapper {
    display: grid;
    flex-wrap: wrap;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    ul {
      padding-left: 8px;
    }
    li {
      list-style-type: none;
      display: inline-block;
      cursor: pointer;
    }
  }
  .bread-crumb {
    margin-left: 10px;
    font-size: 14px;
  }

  .state-locations-wrapper {
    margin-top: 18px;
    margin-left: 6px;
  }
  .location-bread-crumb {
    text-transform: capitalize;
  }
  .text-bold {
    font-weight: 500;
  }
  .bread-crumb-contry {
    cursor: pointer;
  }
}
@media screen and (max-width: 768px) {
  .locations-page {
    padding: 16px;
    &-wrapper {
      grid-template-columns: 1fr;
    }
  }
}
</style>
